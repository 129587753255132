import $ from 'jquery';
import { Link } from 'react-scroll'
import ContactSection from './components/ContactSection'
import RetratosHome from './components/RetratosHome';
import { useNavigate } from 'react-router-dom'

const Home = () => {

    var cfg = {		
		defAnimation   : "fadeInUp",    // default css animation		
		scrollDuration : 800,           // smoothscroll duration
		statsDuration  : 4000           // stats animation duration
	},	
	$WIN = $(window);

    var ssIntroAnimation = function() {

		$WIN.on('load', function() {
		
	     	if (!$("html").hasClass('no-cssanimations')) {
	     		setTimeout(function(){
	    			$('.animate-intro').each(function(ctr) {
						var el = $(this),
	                   animationEfx = el.data('animate') || null;		                                      

	               if (!animationEfx) {
	                 	animationEfx = cfg.defAnimation;	                 	
	               }

	              	setTimeout( function () {
							el.addClass(animationEfx + ' animated');
						}, ctr * 300);
					});						
				}, 100);
	     	} 
		}); 

	};

    ssIntroAnimation()

    const navigate = useNavigate();

    const goToSobre = (e)=>{
        e.preventDefault()
        navigate('/sobre')
    }

    return(
        <div>
            <section id="home">
                <div className="overlay"></div><div className="gradient-overlay"></div>
                <div className="home-content-table">		   
                    <div className="home-content-tablecell">
                        <div className="row">
                            <div className="col-twelve">		
                                <div className="home-bottom-text">
                                    <h3 className="animate-intro" >Olá,</h3>
                                    <h1 className="animate-intro">
                                        Eu sou Bob Sousa. <br/>
                                        Fotógrafo e Pesquisador da <br/>
                                        Cena Teatral. 
                                    </h1>	

                                    <ul className="home-section-links">
                                        <li className="animate-intro">
                                            Mais 
                                            <span className='excessao' onClick={(e)=>goToSobre(e)}>
                                                Sobre Mim
                                            </span>
                                        </li>
                                        <li className="animate-intro">
                                            Veja 
                                            <Link className="smoothscroll" to="portfolio" spy={true} smooth={true} duration={500}>
                                                Meus Trabalhos
                                            </Link>
                                        </li>
                                        <li className="animate-intro">Entre em 
                                            <Link className="smoothscroll" to="contact" spy={true} smooth={true} duration={500}>
                                                Contato
                                            </Link>
                                        </li>								
                                    </ul>
                                </div>
                            </div> 
                        </div> 
                    </div> 		   
                </div>

                <Link className="scroll-icon smoothscroll" to="portfolio" spy={true} smooth={true} style={{cursor:'pointer'}} duration={500}>
                    <span className="icon-DownArrow"></span>
                </Link>	

            </section>
            <RetratosHome />            
            <ContactSection />
        </div>
    )
    
}
export default Home