import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { animateScroll as scroll } from "react-scroll";
import firebase from "firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import ModalText from "./components/ModalText";
import CadTestemonials from "./components/CadTestemonials";

const EditAmargemSingle = () => {
  const { id } = useParams();

  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const [pecas, setPecas] = useState([]);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    db.collection("amargem")
      .where("nome", "==", id)
      .onSnapshot(function (snapshot) {
        setPecas(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);
  useEffect(() => {
    if (pecas.length > 0) {
      db.collection("amargem")
        .doc(pecas[0].id)
        .collection("images")
        .orderBy("timestamp")
        .onSnapshot(function (snapshot) {
          setImageList(
            snapshot.docs.map(function (document) {
              return { id: document.id, data: document.data() };
            })
          );
        });
    }
  }, [pecas]);

  const txtPeca = pecas.length > 0 ? pecas[0].data.texto : "";
  const txtPecaHtml = txtPeca ? parse(txtPeca) : "";

  const scllTop = () => {
    scroll.scrollToTop();
  };
  window.onload(scllTop());
  //////////AÇÕES////////////

  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setFiles((prevState) => [...prevState, newImage]);
    }
  };

  const cadastrarPeca = (e) => {
    e.preventDefault();
    const promises = [];

    files.map((image) => {
      const uploadTask = storage.ref(`amargem/${image.name}`).put(image);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await storage
            .ref("amargem")
            .child(image.name)
            .getDownloadURL()
            .then((urls) => {
              setUrls((prevState) => [...prevState, urls]);
              db.collection("amargem")
                .doc(pecas[0].id)
                .collection("images")
                .add({
                  images: urls,
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                });
            });
        }
      );
    });

    Promise.all(promises)
      .then(() => {
        setFiles("");
        setProgress(0);
        //alert("As imagens foram cadastradas")
      })
      .catch((err) => console.log(err));
  };

  const deleteImage = (e, par) => {
    e.preventDefault();
    db.collection("amargem")
      .doc(pecas[0].id)
      .collection("images")
      .doc(par)
      .delete();
  };

  const changeText = (e) => {
    e.preventDefault();
    let modalBob = document.querySelector(".modal_bob_container");

    modalBob.style.display = "block";
  };

  return (
    <>
      <div className="admin_container">
        <h1>Editar a Peça</h1>
        <div className="edit_wraper">
          {pecas.map((val) => {
            return (
              <div className="edit_container" key={val.id}>
                <ModalText uid={val.id} collection="amargem" />
                <div
                  className="edit_img"
                  style={{ backgroundImage: `url(${val.data.url})` }}
                >
                  <h4>{val.data.nome}</h4>
                </div>
                <br />
                <h5>Texto da peça</h5>
                <div className="txt_peca">{txtPecaHtml}</div>
                <button
                  className="btn01"
                  onClick={(e) => changeText(e, val.id)}
                >
                  Modificar Texto
                </button>
                <br />
                <CadTestemonials uid={val.id} />
                <br />

                <br />
                <form onSubmit={(e) => cadastrarPeca(e)}>
                  <label htmlFor="file" className="img_select">
                    <i className="bi bi-images"></i> Escolher Imagens
                  </label>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleChange}
                    required
                    multiple
                  />
                  <progress value={progress} max="100" />
                  <div className="content_btn">
                    <button className="btn01" type="submit" id="btn_cadastrar">
                      Enviar Imagens
                    </button>
                  </div>
                </form>
              </div>
            );
          })}
        </div>

        <Link to="/gerenciar-amargem">
          <h3>
            <i className="bi bi-arrow-left"></i> Voltar
          </h3>
        </Link>
        <div className="edit_mini_images">
          {imageList.map((val) => {
            return (
              <div
                className="mini_image"
                key={val.id}
                style={{ backgroundImage: `url(${val.data.images})` }}
              >
                <span onClick={(e) => deleteImage(e, val.id)}>
                  <i className="bi bi-x-circle-fill"></i> Deletar Imagem
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default EditAmargemSingle;
