import { Link, animateScroll } from 'react-scroll'

const Footer = ()=>{

    window.onscroll = function() {myFunction()};

    function myFunction() {

    let el = document.querySelector('#go-top')
    
    if (document.documentElement.scrollTop > 500) {
        el.style.display='block'
        el.style.cursor='pointer'
    } else {
        el.style.display='none'
    }
    }


    return(
    <footer>
        <div className="row">

            <div className="col-twelve tab-full social">
                <ul className="footer-social">        
                    <li><a href="https://www.facebook.com/bobsousa.hama" target='_blank'>Facebook</a></li>
                    <li><a href="https://www.instagram.com/bobsousa/" target="_blank">Instagram</a></li>
                </ul> 	      		
            </div> 	

        </div>

        <div id="go-top">
            <Link className="smoothscroll" title="Back to Top" to="top" spy={true} smooth={true} duration={500}>
                <i className="icon-UpArrow"></i>
            </Link>
        </div>
        
    </footer>
    )
}
export default Footer