import { Link, useNavigate } from "react-router-dom";

const NavRouter = () => {
  const navigate = useNavigate();

  return (
    <ul className="nav-list">
      <h3>Galerias</h3>
      <li>
        <Link to="/retratos">Retratos</Link>
      </li>
      <li>
        <Link to="/palco">Palco</Link>
      </li>
      <li>
        <Link to="/amargempoeticasreveladas">
          [Amar(gem)]
        </Link>
      </li>
    </ul>
  );
};
export default NavRouter;
