import ContactSection from "./components/ContactSection"
import AboutSection from "./components/AboutSection"
import CredentialSection from "./components/CredentialSection"

const Sobre =()=>{

    return(
        <section className="sobre">
                <div className='logo_back'></div>
                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3>Sobre o Bob</h3>
                        <h1>O que o Bob faz por aí...</h1>  				   			
                    </div>	
                </div>
                <AboutSection />
                <CredentialSection />
                <ContactSection />
        </section>
    )

}
export default Sobre