import { useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

const CadTestemonials = ({ uid }) => {
  const [file1, setFile1] = useState(null);
  const [testemonials, setTestemonials] = useState([]);

  useEffect(() => {
    db.collection("amargem")
      .doc(uid)
      .collection("testemonials")
      .onSnapshot(function (snapshot) {
        setTestemonials(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const addTestemonial = (e) => {
    e.preventDefault();
    let titulo01 = document.getElementById("titulo01").value;
    let texto01 = document.getElementById("texto01").value;
    let autor01 = document.getElementById("autor01").value;
    let cargo01 = document.getElementById("cargo01").value;
    var storageRef = firebase.storage().ref();

    document.getElementById("btn_cadTest").disabled = true;

    if (file1 === null) {
      alert("Um arquivo deve ser escolhido");
      document.getElementById("btn_cadTest").disabled = false;
    } else {
      let nameRenamed = uuidv4() + file1.name;
      if (
        file1.type === "image/jpg" ||
        file1.type === "image/png" ||
        file1.type === "image/jpeg"
      ) {
        const uploadTask = storageRef
          .child("amargemTestemonial/" + nameRenamed)
          .put(file1);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            //document.getElementById('btn_cadastrar').disabled = true;
          },
          function (error) {
            alert(error.message);
          },
          function () {
            storage
              .ref("amargemTestemonial")
              .child(nameRenamed)
              .getDownloadURL()
              .then(function (url) {
                db.collection("amargem")
                  .doc(uid)
                  .collection("testemonials")
                  .add({
                    titulo: titulo01,
                    autor: autor01,
                    text: texto01,
                    cargo: cargo01,
                    visivel: true,
                    autorImage: url,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    alert("Prefácio cadastrado!");
                  });
                setFile1(null);

                document.getElementById("texto01").value = "";
                document.getElementById("autor01").value = "";
                document.getElementById("cargo01").value = "";
                document.getElementById("file01").value = "";
                document.getElementById("btn_cadTest").disabled = false;
              });
          }
        );
      } else {
        alert("Formato de arquivo não aceito pelo aplicativo.");
      }
    }
  };

  const deleteTestemonial = (e, par) => {
    e.preventDefault();
    db.collection("amargem")
      .doc(uid)
      .collection("testemonials")
      .doc(par)
      .delete();
  };

  return (
    <>
      <div className="container_cadTestemonial">
        <form onSubmit={addTestemonial}>
          <div className="cadTestemonial">
            <span>Texto Autores</span>
            <input
              type="text"
              name="titulo01"
              id="titulo01"
              placeholder="Título"
              style={{ width: "100%" }}
            />
            <textarea
              name="texto01"
              id="texto01"
              placeholder="Texto"
            ></textarea>
            <input
              type="file"
              name="file01"
              id="file01"
              onChange={(e) => setFile1(e.target.files[0])}
            />
            <input
              type="text"
              name="autor01"
              id="autor01"
              placeholder="Autor"
            />
            <label htmlFor="file01">Selecionar img autor</label>
            <input
              type="text"
              name="cargo01"
              id="cargo01"
              placeholder="Cargo do autor"
            />
            <button id="btn_cadTest" type="submit">
              Enviar
            </button>
          </div>
        </form>
      </div>
      <div className="testemonials_icons">
        {testemonials.map((val) => {
          return (
            <div className="testemonials_icons_single" key={val.id}>
              <img alt="" src={val.data.autorImage} />
              <i
                className="bi bi-x-circle"
                onClick={(e) => deleteTestemonial(e, val.id)}
              ></i>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CadTestemonials;
