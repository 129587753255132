import { db } from "../../firebase";

import { TextEditor } from "./Editor";

const ModalText = ({ uid, collection }) => {
  const closeModal = () => {
    let modalBob = document.querySelector(".modal_bob_container");
    modalBob.style.display = "none";
  };
  const atualizarTexto = (e) => {
    e.preventDefault();

    let txtHtml = document.getElementById("TextHtmlDone").value;

    db.collection(collection)
      .doc(uid)
      .update({
        texto: txtHtml,
      })
      .then(() => {
        closeModal();
      });
  };
  return (
    <div className="modal_bob_container" style={{ backgroundColor: "black" }}>
      <div className="text_editor_container">
        <form onSubmit={(e) => atualizarTexto(e)}>
          <TextEditor></TextEditor>
          <button type="submit" id="btn_cadastrar">
            Atualizar
          </button>
        </form>
      </div>

      <div className="clickout" onClick={closeModal}></div>
    </div>
  );
};
export default ModalText;
