import Bobrouter from "./routes/Bobrouter";
import "./App.css";
import "./css/base.css";
import "./css/main.css";
import "./css/vendor.css";

function App() {
  return (
    <div>
      <Bobrouter />
    </div>
  );
}

export default App;
