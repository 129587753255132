import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextEditor } from "./components/Editor";

//assistir o vídeo https://www.youtube.com/watch?v=dYLNg1BWwFo
//https://jpuri.github.io/react-draft-wysiwyg/#/docs

const CadBlog = () => {
  const [file, setFile] = useState(null);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    db.collection("blog").onSnapshot(function (snapshot) {
      setPosts(
        snapshot.docs.map(function (document) {
          return { id: document.id, data: document.data() };
        })
      );
    });
  }, []);

  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9 ]/g, "")
      .replace(/\s+/g, "-")
      .replace("?", "");
  };

  const publicar = (e) => {
    e.preventDefault();
    let nomePeca = document.getElementById("nomePeca").value;
    var storageRef = firebase.storage().ref();
    let nameRenamed = uuidv4() + file.name;
    let txtHtml = document.getElementById("TextHtmlDone").value;
    let slug = generateSlug(nomePeca);

    document.getElementById("btn_cadastrar").disabled = true;

    let checkSomeTitle = posts.filter((el) => el.data.slug === slug);

    if (checkSomeTitle.length > 0) {
      alert("Esse título já existe, favor escolha outro");
    } else {
      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        const uploadTask = storageRef.child("blog/" + nameRenamed).put(file);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            //document.getElementById('btn_cadastrar').disabled = true;
          },
          function (error) {
            alert(error.message);
          },
          function () {
            storage
              .ref("blog")
              .child(nameRenamed)
              .getDownloadURL()
              .then(function (url) {
                db.collection("blog")
                  .add({
                    title: nomePeca,
                    text: txtHtml,
                    visivel: true,
                    url: url,
                    slug: slug,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                  .then(() => {
                    alert("Publicação Cadastrada!");
                    navigate("/gerenciar-blog/" + slug);
                  });
                setFile(null);

                document.getElementById("nomePeca").value = "";
                document.getElementById("imgSelect").value = "";
                document.getElementById("btn_cadastrar").disabled = false;
              });
          }
        );
      } else {
        alert("Formato de arquivo não aceito pelo aplicativo.");
      }
    }
  };

  return (
    <div className="admin_container">
      <h1>Publicar no Blog</h1>
      <form onSubmit={(e) => publicar(e)}>
        <label>Título da Publicação</label>
        <input type="text" id="nomePeca" />

        <label>Texto da Publicação</label>
        <TextEditor></TextEditor>

        <label htmlFor="imgSelect" className="img_select">
          Selecionar a Imagem
        </label>
        <input
          type="file"
          id="imgSelect"
          name="file"
          onChange={(e) => setFile(e.target.files[0])}
          required
        />
        <button type="submit" id="btn_cadastrar">
          Publicar
        </button>
      </form>

      <Link to="/admin">
        <h3>
          <i className="bi bi-arrow-left"></i> Voltar
        </h3>
      </Link>
    </div>
  );
};
export default CadBlog;
