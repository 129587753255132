import { db } from "../firebase";
import { useState, useEffect } from "react";
import ContactSection from "./components/ContactSection";
import Paginator from "./components/Paginator";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import CoverImg from "../img/capa_amargem_rz.jpg";

const Amargem = () => {
  const [pecas, setPecas] = useState([]);
  const [page, setPage] = useState(1);

  const qtdPg = 9;

  useEffect(() => {
    db.collection("amargem")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (snapshot) {
        setPecas(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);

  const finalPage = Math.ceil(pecas.length / qtdPg);

  //console.log(finalPage)

  let x = page * qtdPg - qtdPg;

  const scllTop = () => {
    scroll.scrollToTop();
  };

  const mapedPecas = pecas.map((el, index) => {
    if (index >= x && index < x + qtdPg) {
      return el;
    } else {
      return null;
    }
  });

  const filteredPecas = mapedPecas.filter((mapedPecas) => mapedPecas !== null);

  //////////AÇÕES////////////

  const setPageValue = (e, par) => {
    e.preventDefault();
    const foo = async () => {
      // do something
      await scllTop();
      if (page !== par) {
        setPage(par);
      }
    };
    foo();
  };

  return (
    <>
      <section id="portfolio">
        <div className="logo_back"></div>
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3>Novos Projetos</h3>
            <h1>[Amar(gem)] + Poéticas Reveladas</h1>
          </div>
        </div>
        <div
          className="capa_amargem"
          style={{
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/bobsousa-255b9.appspot.com/o/cover%2Fcapa_amargem_rz.jpg?alt=media&token=89e71b70-6a17-4b51-ba18-a9a0944d9ac7)`,
          }}
        ></div>
        <div className="texto_amargem">
          <p>
            Quando afluentes se encontram abre-se uma terceira margem. Da junção
            de pesquisas de Bob Sousa e Simone Carleto emerge espaço-diálogo em
            construção com grupos de teatro a partir dos pressupostos da
            elaboração coletiva de imagens, como representações que se formam
            sobre acontecimentos e espetáculos teatrais.
          </p>
          <p>
            Jorge Dubatti considera a poética teatral o conjunto de componentes
            constitutivos dos entes poéticos, em sua dupla articulação entre
            processo e produto, integrados ao acontecimento teatral em uma
            unidade material-formal ontologicamente específica. Para Alexandre
            Mate o espetáculo se trata de um experimento
            estético-histórico-social, o que enfatiza o pressuposto segundo o
            qual o fenômeno teatral é produzido pelo encontro da ação artística
            criadora com o público. Desse engrossamento farão parte as
            reverberações da obra cênica. Entretanto, como apurá-las? Como
            perceber a permeabilidade dos espetáculos às imagens criadas para
            além das previstas? Assim, nosso diálogo começa no momento antes do
            espetáculo, passa pela captura sensível do momento efêmero da cena,
            se encaminha para a tentativa de abertura a dialogar com a obra por
            meio de palavras em forma de texto, e desse modo prolongar as trocas
            suscitadas pelo encontro do espetáculo com o público.
          </p>
          <p>
            Pensamos em contribuir com nossos olhares para observar, diferentes
            poiésis teatrais, conforme Dubatti, em suas dimensões produtivas,
            receptivas e da zona de experiência que se funda na prática do
            convívio. Pretende-se ainda, nutrir ambas pesquisas de fazer
            criativo processual em conjunto com todas aquelas que se
            interessarem pela fruição e produção teatral, que se direcionam a
            ampliar o espectro da cena como transformação de espaços e mentes em
            férteis territórios, nos quais a visualidade imprime possibilidades
            simbólicas como matéria enraizada na utopia.
          </p>
        </div>
        <div className="palco_wrapper">
          {filteredPecas.map((val) => {
            return (
              <div
                className="container_peca"
                key={val.id}
                style={{ marginBottom: "30px" }}
              >
                <div
                  className="palco_img"
                  style={{ backgroundImage: `url(${val.data.url})` }}
                >
                  <Link to={`/amargempoeticasreveladas/${val.data.nome}`}>
                    <div className="palco_overlay">+</div>
                  </Link>
                </div>
                <h4>{val.data.nome}</h4>
              </div>
            );
          })}
        </div>
        <Paginator atual={page} final={finalPage} acao={setPageValue} />
      </section>

      <ContactSection />
    </>
  );
};
export default Amargem;
