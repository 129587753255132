import { db } from "../../firebase";
import { useState, useEffect } from "react";
import ModalBob from "./ModalBobHome";
import { animateScroll as scroll } from "react-scroll";

const RetratosHome = () => {
  const [retratos, setRetratos] = useState([]);
  const [modalIndex, setModalIndex] = useState(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const scllTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    db.collection("retratosHome")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (snapshot) {
        setRetratos(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scllTop();
  }, []);

  const setIndexNull = () => {
    setModalIndex(null);
  };

  const openModal = (e, par) => {
    e.preventDefault();
    let modalBob = document.querySelector(".modal_bob_container");

    const foo = () => {
      setModalIndex(par);
    };
    const goo = async () => {
      await foo();
      modalBob.style.display = "block";
    };
    goo();
  };

  function reportWindowSize() {
    setWindowSize(window.innerWidth);
  }
  window.onresize = reportWindowSize;

  const retratosVisiveis = retratos.filter(
    (retrato) => retrato.data.visivel === true
  );

  return (
    <>
      <ModalBob index={modalIndex} action={setIndexNull} />
      <section id="portfolio">
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3>Meus Trabalhos</h3>
            <h1>Aquele segundo que a arte olha para a lente.</h1>
          </div>
        </div>

        <div className="retratos_wrapper_home">
          {windowSize > 789 ? (
            <>
              <div className="retrato_container_home">
                {retratosVisiveis.map((val, index) => {
                  return (
                    <div
                      key={val.id}
                      onClick={(e) => openModal(e, index)}
                      className="retrato_content_home_hl"
                    >
                      {index % 2 === 0 ? (
                        <div
                          className="retrato_content_home"
                          style={{ backgroundImage: `url(${val.data.url})` }}
                        >
                          <div className="retrato_content_home_overlay">
                            <h4>{val.data.nome}</h4>
                            <h3>+</h3>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="retrato_container_home">
                {retratosVisiveis.map((val, index) => {
                  return (
                    <div key={val.id} onClick={(e) => openModal(e, index)}>
                      {index % 2 !== 0 ? (
                        <div
                          className="retrato_content_home"
                          style={{ backgroundImage: `url(${val.data.url})` }}
                        >
                          <div className="retrato_content_home_overlay">
                            <h4>{val.data.nome}</h4>
                            <h3>+</h3>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="retrato_container_home">
              {retratosVisiveis.map((val, index) => {
                return (
                  <div key={val.id} onClick={(e) => openModal(e, index)}>
                    <div
                      className="retrato_content_home"
                      style={{ backgroundImage: `url(${val.data.url})` }}
                    >
                      <div className="retrato_content_home_overlay">
                        <h4>{val.data.nome}</h4>
                        <h3>+</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default RetratosHome;
