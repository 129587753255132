import {db, storage} from '../firebase'
import { useState, useEffect } from 'react';
import ContactSection from './components/ContactSection'
import { animateScroll as scroll} from 'react-scroll'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ModalBobPalco from './components/ModalBobPalco'
import parse from 'html-react-parser';

const Projetos = ()=>{

    const {id} = useParams();
    const [projeto, setProjeto] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [modalIndex, setModalIndex] = useState(null);

    const navigate = useNavigate();

    useEffect(()=>{
            db.collection('projetos').where('nome','==',id).onSnapshot(function(snapshot){
                setProjeto(snapshot.docs.map(function(document){
                    return{id:document.id,data:document.data()}
                }))
            })
            scroll.scrollToTop(); 
    },[])

    useEffect(()=>{
        if(projeto.length > 0){
            db.collection('projetos').doc(projeto[0].id).collection('images').onSnapshot(function(snapshot){
                setImageList(snapshot.docs.map(function(document){
                    return{id:document.id,data:document.data()}
                }))
            })
        }
    },[projeto])
          
    const nomePeca = projeto.length > 0 ? projeto[0].data.nome : '';
    const imgCapa = projeto.length > 0 ? projeto[0].data.url : '';
    const txtPeca = projeto.length > 0 ? projeto[0].data.texto : '';
    const txtPecaHtml = txtPeca ? parse(txtPeca) : ''

    const setIndexNull = ()=>{
        setModalIndex(null)
    }
    const openModal =(e, par)=>{
        e.preventDefault();
        let modalBob = document.querySelector('.modal_bob_container');
        
        const foo = ()=>{
            setModalIndex(par)
        }
        const goo = async ()=>{
            await foo()
            modalBob.style.display="block"
        }
        goo()
    }

    const obj = imageList.length > 0 ? imageList.map(val => val.data.images) : [];


    return(
        <>  
        <ModalBobPalco index={modalIndex} action={setIndexNull} obj={obj} />
            <section id='portfolio'>
                <div className='logo_back'></div>
                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3>Projetos</h3>
                        <h1>{nomePeca}</h1>	
                    </div>
                </div>
                <div className='capa_palco_single' style={{backgroundImage:`url(${imgCapa})`}}></div>
                <div className='texto_palco'>
                    { txtPecaHtml }
                </div>
                <div className="palco_single_wrapper">
                    {imageList.map((val, index)=>{
                        return(
                            <div key={val.id} className="palco_single_img" style={{backgroundImage:`url(${val.data.images})`}} onClick={(e)=>openModal(e, index)}>
                                <div className="palco_single_content">
                                    <h3>+</h3>
                                </div>
                            </div> 
                        )
                    })}  
                </div>
            </section>
            
            <ContactSection />
        </>
        )
}
export default Projetos