import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  HashRouter,
} from "react-router-dom";
//Se for subir o site através do Hostinger, substituir as Routers por Hashrouters

import { useEffect, useState } from "react";
import { auth } from "../firebase.js";
import Header from "../layout/Header.js";
import Footer from "../layout/Footer.js";
import Home from "../pages/Home.js";
import Retratos from "../pages/Retratos.js";
import Palco from "../pages/Palco.js";
import PalcoSingle from "../pages/PalcoSingle.js";
import UploadPalco from "../pages/UploadPalco.js";
import Admin from "../pages/Admin.js";
import CadRetratos from "../pages/CadRetratos.js";
import CadRetratosHome from "../pages/CadRetratosHome.js";
import CadPecas from "../pages/CadPecas.js";
import EditPecas from "../pages/EditPecas.js";
import EditPeca from "../pages/EditPeca.js";
import Sobre from "../pages/Sobre.js";
import EditRetratos from "../pages/EditRetratos.js";
import EditRetrato from "../pages/EditRetrato.js";
import EditRetratosHome from "../pages/EditRetratosHome.js";
import EditRetratoHome from "../pages/EditRetratoHome.js";
import CadProjects from "../pages/CadProjects.js";
import EditProjects from "../pages/EditProjects.js";
import EditProject from "../pages/EditProject.js";
import Projetos from "../pages/Projetos.js";
import LoginBob from "../pages/Login.js";
import Amargem from "../pages/Amargem.js";
import AmargemSingle from "../pages/AmargemSingle.js";
import CadAmargem from "../pages/CadAmargem.js";
import UploadAmargem from "../pages/UploadAmargem.js";
import EditAmargem from "../pages/EditAmargem.js";
import EditAmargemSingle from "../pages/EditAmargemSingle.js";

//Cadastro e alterações no Blog
import CadBlog from "../pages/CadBlog.js";
import EditBlog from "../pages/EditBlog.js";
import EditBlogPost from "../pages/EditBlogPost.js";

const Bobrouter = () => {
  const [login, setLogin] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((val) => {
      if (val != null) {
        setLogin({
          email: val.email,
          uid: val.uid,
        });
      }
    });
  }, []);

  const logar = (e) => {
    e.preventDefault();
    //parametros
    let email = document.getElementById("email-login").value;
    let senha = document.getElementById("senha-login").value;

    auth
      .signInWithEmailAndPassword(email, senha)
      .then((auth) => {
        setLogin(auth.user.email);
        window.location.href = "/#/admin";
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  /*function logar(e){
        e.preventDefault();
        //parametros
        let email = document.getElementById('email-login').value;
        let senha = document.getElementById('senha-login').value;
       
        auth.signInWithEmailAndPassword(email,senha)
        .then((auth)=>{
            setLogin(auth.user.email);
            //window.location.href = "/#/admin";
        }).catch((err)=> {
            alert(err.message);
        })
    }*/

  const deslogar = (e) => {
    e.preventDefault();
    auth.signOut().then(function () {
      window.location.href = "/#/admin";
      setLogin(null);
    });
  };

  return (
    <div>
      {login ? (
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Header page="home" />
                  <Home />
                </>
              }
            />
            <Route
              exact
              path="/retratos"
              element={
                <>
                  <Header page="retratos" />
                  <Retratos />
                </>
              }
            />
            <Route
              exact
              path="/palco"
              element={
                <>
                  <Header page="retratos" />
                  <Palco />
                </>
              }
            />
            <Route
              exact
              path="/amargempoeticasreveladas"
              element={
                <>
                  <Header page="retratos" />
                  <Amargem />
                </>
              }
            />
            <Route
              exact
              path="/amargempoeticasreveladas/:id"
              element={
                <>
                  <Header page="retratos" />
                  <AmargemSingle />
                </>
              }
            />
            <Route
              exact
              path="/sobre"
              element={
                <>
                  <Header page="retratos" />
                  <Sobre />
                </>
              }
            />
            <Route
              exact
              path="/palco/:id"
              element={
                <>
                  <Header page="retratos" />
                  <PalcoSingle />
                </>
              }
            />
            <Route
              exact
              path="/projetos/:id"
              element={
                <>
                  <Header page="retratos" />
                  <Projetos />
                </>
              }
            />

            {/* ADMIN */}

            <Route
              exact
              path="/admin"
              element={
                <>
                  <Header page="retratos" />
                  <Admin deslogar={deslogar} />
                </>
              }
            />
            <Route
              exact
              path="/cadastro-retratos"
              element={
                <>
                  <Header page="retratos" />
                  <CadRetratos />
                </>
              }
            />
            <Route
              exact
              path="/cadastro-retratos-home"
              element={
                <>
                  <Header page="retratos" />
                  <CadRetratosHome />
                </>
              }
            />
            <Route
              exact
              path="/cadastro-pecas"
              element={
                <>
                  <Header page="retratos" />
                  <CadPecas />
                </>
              }
            />
            <Route
              exact
              path="/cadastro-blog"
              element={
                <>
                  <Header page="retratos" />
                  <CadBlog />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-blog"
              element={
                <>
                  <Header page="retratos" />
                  <EditBlog />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-blog/:id"
              element={
                <>
                  <Header page="retratos" />
                  <EditBlogPost />
                </>
              }
            />
            <Route
              exact
              path="/cadastro-amargem"
              element={
                <>
                  <Header page="retratos" />
                  <CadAmargem />
                </>
              }
            />
            <Route
              exact
              path="/uploadpalco"
              element={
                <>
                  <Header page="retratos" />
                  <UploadPalco />
                </>
              }
            />
            <Route
              exact
              path="/uploadamargem"
              element={
                <>
                  <Header page="retratos" />
                  <UploadAmargem />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-pecas"
              element={
                <>
                  <Header page="retratos" />
                  <EditPecas />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-amargem"
              element={
                <>
                  <Header page="retratos" />
                  <EditAmargem />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-pecas/:id"
              element={
                <>
                  <Header page="retratos" />
                  <EditPeca />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-amargem/:id"
              element={
                <>
                  <Header page="retratos" />
                  <EditAmargemSingle />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-retratos"
              element={
                <>
                  <Header page="retratos" />
                  <EditRetratos />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-retratos/:id"
              element={
                <>
                  <Header page="retratos" />
                  <EditRetrato />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-retratos-home"
              element={
                <>
                  <Header page="retratos" />
                  <EditRetratosHome />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-retratos-home/:id"
              element={
                <>
                  <Header page="retratos" />
                  <EditRetratoHome />
                </>
              }
            />
            <Route
              exact
              path="/cadastro-projetos"
              element={
                <>
                  <Header page="retratos" />
                  <CadProjects />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-projetos"
              element={
                <>
                  <Header page="retratos" />
                  <EditProjects />
                </>
              }
            />
            <Route
              exact
              path="/gerenciar-projetos/:id"
              element={
                <>
                  <Header page="retratos" />
                  <EditProject />
                </>
              }
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Header page="home" />
                  <Home />
                </>
              }
            />
            <Route
              exact
              path="/retratos"
              element={
                <>
                  <Header page="retratos" />
                  <Retratos />
                </>
              }
            />
            <Route
              exact
              path="/palco"
              element={
                <>
                  <Header page="retratos" />
                  <Palco />
                </>
              }
            />
            <Route
              exact
              path="/amargempoeticasreveladas"
              element={
                <>
                  <Header page="retratos" />
                  <Amargem />
                </>
              }
            />
            <Route
              exact
              path="/amargempoeticasreveladas/:id"
              element={
                <>
                  <Header page="retratos" />
                  <AmargemSingle />
                </>
              }
            />
            <Route
              exact
              path="/sobre"
              element={
                <>
                  <Header page="retratos" />
                  <Sobre />
                </>
              }
            />
            <Route
              exact
              path="/palco/:id"
              element={
                <>
                  <Header page="retratos" />
                  <PalcoSingle />
                </>
              }
            />
            <Route
              exact
              path="/projetos/:id"
              element={
                <>
                  <Header page="retratos" />
                  <Projetos />
                </>
              }
            />

            {/* ADMIN */}

            <Route exact path="/admin" element={<LoginBob logar={logar} />} />
            <Route
              exact
              path="/cadastro-retratos"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/cadastro-retratos-home"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/cadastro-pecas"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/cadastro-blog"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/uploadpalco"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-pecas"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-pecas/:id"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-amargem/:id"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-amargem"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/cadastro-amargem"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-retratos"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-retratos/:id"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-retratos-home"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-retratos-home/:id"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/cadastro-projetos"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-projetos"
              element={<LoginBob logar={logar} />}
            />
            <Route
              exact
              path="/gerenciar-projetos/:id"
              element={<LoginBob logar={logar} />}
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </div>
  );
};
export default Bobrouter;
