const CredentialsSection = ()=>{
    return(
        <section id="credentials">
                
                <div className="row narrow section-intro with-bottom-sep">
                    <div className="col-twelve">
                        <h3>Experiências e reconhecimentos</h3>
                        <h1>Premiações na Cultura.</h1>  				   			
                    </div>   		
                </div>

                <div className="row wide credentials-content">
                        <div className="col-twelve tab-full left">
                        <div className="timeline-wrap">
                        <div className="timeline-block ">
                            <div className="timeline-ico">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="timeline-header">
                                <h3>Indicação ao Prêmio CPT</h3>	   					
                            </div>
                                <div className="timeline-content">	   					
                                    <p>Indicado para o Premio CPT – Cooperativa Paulista de Teatro – 2012,
                                        pela vasta produção fotográfica dos coletivos teatrais paulistanos.</p>
                                </div>
                            </div>

                            <div className="timeline-block ">
                                <div className="timeline-ico">
                                    <i className="fa fa-check"></i>
                                </div>
                                <div className="timeline-header">
                                    <h3>Ordem do Mérito e da Arte e da Cultura Nelson Rodrigues</h3>   				
                                </div>
                                <div className="timeline-desc">	   					
                                    <p>Ganhador da Ordem do Mérito e da Arte e da Cultura Nelson Rodrigues,
                                    em 2015, pela sua contribuição ao teatro</p>
                                </div>
                            </div>

                            <div className="timeline-block ">
                                <div className="timeline-ico">
                                    <i className="fa fa-check"></i>
                                </div>
                                <div className="timeline-header">
                                    <h3>Selo Referência Nacional - ANCEC</h3> 					
                                </div>
                                <div className="timeline-content">	   					
                                    <p>Selo Referência Nacional por seu trabalho em retratar artistas e
                                    personalidades da cultura, em 2019, pela ANCEC - Agência Nacional de
                                    Cultura, Empreendedorismo e Comunicação</p>
                                </div>
                            </div>
                        </div>			
                    </div>		
                </div>  	
            </section>
    )
}
export default CredentialsSection