import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { animateScroll as scroll } from "react-scroll";
import firebase from "firebase";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import ModalText2 from "./components/ModalText2";

const EditBlogPost = () => {
  const { id } = useParams();

  const [info, setInfo] = useState({
    author: "",
    title: "",
    photoBy: "",
    description: "",
  });

  const [pecas, setPecas] = useState([]);

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection("blog")
      .where("slug", "==", id)
      .onSnapshot(function (snapshot) {
        setPecas(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    db.collection("blog").onSnapshot(function (snapshot) {
      setPosts(
        snapshot.docs.map(function (document) {
          return { id: document.id, data: document.data() };
        })
      );
    });
    scroll.scrollToTop();
  }, []);
  useEffect(() => {
    if (pecas.length > 0) {
      setInfo({
        author: pecas[0].data.author,
        title: pecas[0].data.title,
        photoBy: pecas[0].data.photoBy,
      });
    }
  }, [pecas]);

  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9 ]/g, "")
      .replace(/\s+/g, "-")
      .replace("?", "");
  };

  const txtPeca = pecas.length > 0 ? pecas[0].data.text : "";
  const txtPecaHtml = txtPeca ? parse(txtPeca) : "";

  const scllTop = () => {
    scroll.scrollToTop();
  };
  window.onload(scllTop());

  const changeText = (e) => {
    e.preventDefault();
    let modalBob = document.querySelector(".modal_bob_container");

    modalBob.style.display = "block";
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const updateInfo = (e) => {
    e.preventDefault();
    let title = info.title;
    let author = info.author !== "" ? info.author : null;
    let photoBy = info.photoBy !== "" ? info.photoBy : null;
    let description = info.description !== "" ? info.description : null;
    let slug = generateSlug(title);

    let checkSomeTitle = posts.filter((el) => el.data.slug === slug);

    if (checkSomeTitle.length > 0 && pecas[0].data.slug !== slug) {
      alert("Esse título já existe, favor escolha outro");
    } else {
      if (title !== "") {
        db.collection("blog")
          .doc(pecas[0].id)
          .update({
            title: title,
            author: author,
            photoBy: photoBy,
            slug: slug,
            description: description,
          })
          .then(() => alert("Alterações salvas!"));
      } else {
        alert("Deve haver um título.");
      }
    }
  };

  return (
    <>
      <div className="admin_container">
        <h1>Editar Postagem</h1>
        <form onSubmit={updateInfo}>
          <label>Título da postagem</label>
          <input
            type="text"
            id="post_title"
            name="title"
            value={info.title}
            onChange={handleInfoChange}
            style={{ textAlign: "center" }}
          />
          <label>Autor do texto da postagem (não obrigatório)</label>
          <input
            type="text"
            id="post_author"
            name="author"
            value={info.author}
            onChange={handleInfoChange}
            style={{ textAlign: "center" }}
          />
          <label>Fotógrafo da imagem (não obrigatório)</label>
          <input
            type="text"
            id="post_photoBy"
            name="photoBy"
            value={info.photoBy}
            onChange={handleInfoChange}
            style={{ textAlign: "center" }}
          />
          <label>Descrição</label>
          <textarea
            style={{ minWidth: "400px", maxWidth: "80%" }}
            id="description"
            name="description"
            value={info.description}
            onChange={handleInfoChange}
          ></textarea>
          <button type="submit">Salvar</button>
        </form>
        <div className="edit_wraper">
          {pecas.map((val) => {
            return (
              <div className="edit_container" key={val.id}>
                <ModalText2 uid={val.id} collection="blog" />
                <div
                  className="edit_img"
                  style={{ backgroundImage: `url(${val.data.url})` }}
                >
                  <h4>{val.data.title}</h4>
                </div>
                <br />
                <h5>Texto da Postagem</h5>
                <div className="txt_peca">{txtPecaHtml}</div>
                <button
                  className="btn01"
                  onClick={(e) => changeText(e, val.id)}
                >
                  Modificar Texto
                </button>
                <br />
              </div>
            );
          })}
        </div>

        <Link to="/gerenciar-blog">
          <h3>
            <i className="bi bi-arrow-left"></i> Voltar
          </h3>
        </Link>
      </div>
    </>
  );
};
export default EditBlogPost;
