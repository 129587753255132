import { Link, Scroll } from 'react-scroll'
import { useNavigate } from 'react-router-dom'

const NavScroll =({page})=>{

    const navigate = useNavigate();
    
    const goToRoute = (par)=>{
        navigate(par)
        window.location.reload()
    }

    return(
        <ul className="nav-list">
            {page !== 'home'
            ? (<>

                <li className="current">
                    <a onClick={()=>goToRoute('/')}>Home</a>
                </li>
                <li className="current">
                    <a onClick={()=>goToRoute('/sobre')}>Sobre</a>
                </li>
                <li>
                    <Link className="" to="contact" spy={true} smooth={true} duration={500}>
                    Contato
                    </Link>
                </li>
            
            </>)
            : (<>
                <li className="current">
                    <Link className="" to="home" spy={true} smooth={true} duration={500}>
                    Topo
                    </Link>
                </li>
                <li>
                    <Link className="" to="portfolio" spy={true} smooth={true} duration={500}>
                    Meus Trabalhos
                    </Link>
                </li>
                <li className="current">
                    <a onClick={()=>goToRoute('/sobre')}>Sobre</a>
                </li>
                <li>
                    <Link className="" to="contact" spy={true} smooth={true} duration={500}>
                    Contato
                    </Link>
                </li>
            </>)}
            					
        </ul>
)
}
export default NavScroll