const AboutSection = () => {
  return (
    <section id="about">
      <div className="about-profile-bg"></div>
      <div className="about-wrap">
        <div className="about-profile">
          <div className="about-profile-bg"></div>
          <div className="intro">
            <h3>Sobre</h3>
            <h1>Um pouco mais sobre mim.</h1>
          </div>
          <p>
            Bob Sousa é mestre em artes cênicas e doutorando pelo Instituto de
            Artes da Unesp, com a pesquisa intitulada O lugar da fotografia na
            cena teatral paulistana no século XXI e autor dos livros Retratos do
            teatro (Editora Unesp) e Atos de Coexistência – 30 anos do Núcleo de
            Artes Cênicas do Sesi-SP (Editora Sesi).
          </p>

          <p>
            Integra a comissão do juri de Teatro da APCA – Associação Paulista
            de Críticos de Arte – SP e do Prêmio Arcanjo de Cultura. Na última
            década, os mais importantes espetáculos que ocuparam os palcos da
            cidade foram captados por seu olhar, e foram centenas de
            profissionais que tiveram seus projetos documentados.
          </p>

          <p>
            Nos últimos 20 anos, fotografou mais de 300 espetáculos teatrais e
            festivais de teatro, dentre os quais o Festival MIRADA/SESC nas
            edições 2016/2018 e 2022 e a Mostra Internacional de Teatro de São
            Paulo, MITsp. É responsável pelo registro dos figurinos da obra do
            Grupo Macunaíma, de Antunes Filho, para a Coleções e Acervos
            Históricos do CPT_ Sesc. Colabora com a Cena Contemporânea, da
            Revista CULT, com a coluna "O Retrato do Bob", no Blog do jornalista
            Miguel Arcanjo Prado e mantêm a Coluna "Imagética", com textos
            críticos e fotografia de cena no site da SP Escola de Teatro, desde
            2014.
          </p>

          <p>
            Integra o grupo de estudos Práxis épico-populares em perspectivas
            críticas: documentação de experimentos teatrais, coordenado pelo
            Profº Dr. Alexandre Mate e o Portal de História do Teatro Mundial e
            Brasileiro, coordenado, de modo que suas obras passarão a fazer
            parte do acervo da Biblioteca do Instituto de Artes da UNESP
            (Universidade Estadual Paulista) e estarão à disposição no portal
            online (em construção), vinculado a pesquisa teatral realizada pela
            universidade. Indicado para o Premio CPT – Cooperativa Paulista de
            Teatro – 2012, pela vasta produção fotográfica dos coletivos
            teatrais paulistanos.
          </p>

          <p>
            Parte de sua produção exibida em exposições como a intitulada
            “Instante Eterno”, em 2009 e outras mostras individuais. Em 2022,
            apresenta a exposição RETRATOS – Bob Sousa, com artistas da cena
            teatral brasileira, nos teatros da cidade de São Paulo. Nos últimos
            anos, tem se dedicado ainda a uma Série de Retratos de Artistas do
            Teatro Brasileiro e internacionais.
          </p>
        </div>

        <div className="about-services">
          <div className="intro">
            <h3>Fotografia de Cena</h3>
            <h1>Aquele que ninguém vê...</h1>
            <p className="lead ">
              Por trás dos holofotes, alguém procura registrar os detalhes de
              cada movimento que acontece no mundo do teatro.
            </p>
          </div>

          <ul className="services">
            <li className="item-branding ">
              <div className="icon-wrap">
                <i className="icon-BusinessContact"></i>
              </div>
              <div className="service-item">
                <p>
                  Bob Sousa (São Paulo, 25 de outubro de 1972), é fotógrafo de
                  teatro de São Paulo. Possuiu um acervo pessoal de imagens onde
                  estão registrados mais de 300 espetáculos.
                </p>
              </div>
            </li>
            <li className="item-photography ">
              <div className="icon-wrap">
                <i className="icon-GraduationCap"></i>
              </div>
              <div className="service-item">
                <p>
                  Graduado em Publicidade e Propaganda pela Universidade
                  Paulista em 2001, profissionalizou-se na área de fotografia
                  teatral em 2002.Em 2015 tornou-se mestre em artes cênicas pelo
                  Instituto de Artes da Unesp.
                </p>
              </div>
            </li>
            <li className="item-webdesign ">
              <div className="icon-wrap">
                <i className="icon-Bookmark"></i>
              </div>
              <div className="service-item">
                <p>
                  Suas principais obras são: "Retratos do Teatro - Editora
                  Unesp, 2013"; O lugar da fotografia na cena teatral paulistana
                  no século XXI - dissertação para obtenção do título de mestre
                  em artes cênicas para o Instituto de Artes da Unesp, 2015" e
                  "Atos de Coexistência - 30 anos do núcleo de Artes Cênicas do
                  SESI - Editora SESI - SP, 2017", " e "Retratos do Teatro -
                  Editora Unesp, 2016".
                </p>
              </div>
            </li>
            <li className="item-uiux ">
              <div className="icon-wrap">
                <i className="icon-Diploma1"></i>
              </div>
              <div className="service-item">
                <p>
                  Em 2023, além de realizar a exposição itinerante "Retratos -
                  Bob Sousa", torna-se membro da comissão de teatro da APCA -
                  Associação Paulista de Criticos de Arte - SP.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
export default AboutSection;
