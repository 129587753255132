import { useEffect, useState } from "react";
import { db } from "../../firebase";

const Testemonials = ({ uid }) => {
  const [testemonials, setTestemonials] = useState([]);
  useEffect(() => {
    db.collection("amargem")
      .doc(uid)
      .collection("testemonials")
      .onSnapshot(function (snapshot) {
        setTestemonials(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);
  return (
    <div className="testemonials_container">
      {testemonials.map((val) => {
        return (
          <div className="testemonial_single" key={val.id}>
            <div className="testemonial_single_img">
              <img alt="" src={val.data.autorImage} />
            </div>
            <h4>{val.data.titulo}</h4>
            <p>
              <span>“</span>
              {val.data.text}
              <span>”</span>
            </p>
            <h6>
              {val.data.autor}
              <span>{val.data.cargo}</span>
            </h6>
          </div>
        );
      })}
    </div>
  );
};

export default Testemonials;
