import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactSection =()=>{
    
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    document.getElementById('btnsubmit').disabled = true;
    emailjs.sendForm('service_xlsjnbt', 'template_bu3giik', form.current, 'pp3Dyv_B8c-17f4p2')
      .then((result) => {
          console.log(result.text);
          console.log('mensagem enviada!');
          alert("Sua mensagem foi enviada! Em breve, entrarei em contato!")
      }, (error) => {
          console.log(error.text);
      });

      document.getElementById('form').reset();
      document.getElementById('btnsubmit').disabled = false;
  };

    return(
        <section id="contact">
                <div className="overlay"></div>
                <div className="row narrow section-intro">
                    <div className="col-twelve">
                        <h3>Contato</h3>
                        <h1>Todo grande trabalho começa numa pequena troca de ideias.</h1>
                        <p className="lead ">Deixe uma mensagem para iniciarmos nossa pesquisa.</p>
                    </div> 
                </div>

   	            <div className="row contact-form">
   		            <div className="col-twelve">
                        <form name="contactForm" form ref={form} onSubmit={sendEmail} id='form'>
                            <div className="form-field ">
 						        <input name="user_name" type="text" id="user_name" placeholder="Nome..." defaultValue="" minLength="2" required />
                            </div>
                            <div className="form-field ">
                                <input name="user_email" type="email" id="user_email" placeholder="E-mail..." defaultValue="" required />
                            </div>
                            <div className="form-field ">
                                    <input name="subject" type="text" id="subject" placeholder="Assunto..." defaultValue="" />
                            </div>                       
                            <div className="form-field ">
                                <textarea name="message" id="message" placeholder="Escreva sua mensagem..." rows="10" cols="50" required=""></textarea>
                            </div> 

                            <div className="form-field ">
                                <button className="submitform" id='btnsubmit'>Enviar</button>
                                <div id="submit-loader">
                                    <div className="text-loader">Sending...</div>                             
                                        <div className="s-loader">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </div>
                            </div>	
      		            </form>
                    <div id="message-warning"></div> 
      		    <div id="message-success">
                    <i className="fa fa-check"></i>Your message was sent, thank you!<br/>
      		    </div>
                    </div> 		
   	            </div>
                <div className="row contact-info">


                <div className="col-six tab-full collapse">
                    <h5>Envie-me um e-mail</h5>

                    <p>
                        bobsousa@bobsousa.com<br/>
                    </p>
                </div>

                <div className="col-six tab-full">
                    <h5>Entre em contato</h5>

                    <p>
                        Whatsapp: (11) 98210-9901
                    </p>
                </div>
            
                </div>
		
	        </section>
        )
}
export default ContactSection