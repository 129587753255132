import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import firebase from "firebase";

const UploadAmargem = () => {
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState("");
  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      setFiles((prevState) => [...prevState, newImage]);
    }
  };

  const cadastrarPeca = (e) => {
    e.preventDefault();
    const promises = [];

    files.map((image) => {
      const uploadTask = storage.ref(`amargem001/${image.name}`).put(image);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        async () => {
          await storage
            .ref("amargem001")
            .child(image.name)
            .getDownloadURL()
            .then((urls) => {
              setUrls((prevState) => [...prevState, urls]);
              db.collection("amargem")
                .doc("OrmrL3aC2GPUTrL4SidR")
                .collection("images")
                .add({
                  images: urls,
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                });
            });
        }
      );
    });

    Promise.all(promises)
      .then(() => alert("As imagens foram cadastradas"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="admin_container">
      <progress value={progress} max="100" />
      <h1>Upload Palco</h1>
      <form onSubmit={(e) => cadastrarPeca(e)}>
        <input
          type="text"
          name="nome_peca"
          placeholder="Nome..."
          id="nomePeca"
          required
        />
        <label htmlFor="file">Escolher arquivo</label>
        <input
          type="file"
          id="file"
          name="file"
          onChange={handleChange}
          required
          multiple
        />
        <div className="content_btn">
          <button className="btn01" type="submit" id="btn_cadastrar">
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  );
};
export default UploadAmargem;
