import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { animateScroll as scroll } from "react-scroll";
import Paginator from "./components/Paginator";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";

const EditPecas = () => {
  const [pecas, setPecas] = useState([]);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const qtdPg = 18;

  useEffect(() => {
    db.collection("pecas")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (snapshot) {
        setPecas(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);

  const finalPage = Math.ceil(pecas.length / qtdPg);

  //console.log(finalPage)

  let x = page * qtdPg - qtdPg;

  const scllTop = () => {
    scroll.scrollToTop();
  };

  const mapedPecas = pecas.map((el, index) => {
    if (index >= x && index < x + qtdPg) {
      return el;
    } else {
      return null;
    }
  });

  const filteredPecas = mapedPecas.filter((mapedPecas) => mapedPecas !== null);

  //////////AÇÕES////////////

  const setPageValue = (e, par) => {
    e.preventDefault();
    const foo = async () => {
      // do something
      await scllTop();
      if (page !== par) {
        setPage(par);
      }
    };
    foo();
  };

  const deleteVal = (e, par) => {
    e.preventDefault();
    db.collection("pecas").doc(par).delete();
  };

  const editVal = (e, par) => {
    e.preventDefault();
    navigate("/gerenciar-pecas/" + par);
  };

  ///CAso precise gerar slugs usar funções abaixo
  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9 ]/g, "")
      .replace(/\s+/g, "-")
      .replace("?", "");
  };

  const updateSlugs = async () => {
    pecas.forEach(async (peca) => {
      if (!peca.data.slug) {
        const slug = generateSlug(peca.data.nome);
        await db.collection("pecas").doc(peca.id).update({ slug });
      }
    });
  };

  return (
    <div className="admin_container">
      <h1>Editar Peças</h1>
      <div className="edit_wraper">
        {filteredPecas.map((val) => {
          return (
            <div className="edit_container" key={val.id}>
              <div
                className="edit_img"
                style={{ backgroundImage: `url(${val.data.url})` }}
              >
                <h4 style={{ color: "white" }}>{val.data.nome}</h4>
              </div>
              <div className="edit_control">
                <span onClick={(e) => deleteVal(e, val.id)}>
                  <i className="bi bi-x-circle-fill"></i> Deletar
                </span>
                <span onClick={(e) => editVal(e, val.data.slug)}>
                  <i className="bi bi-pencil-fill"></i> Editar
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Paginator atual={page} final={finalPage} acao={setPageValue} />
      </div>
      <br />
      <Link to="/admin">
        <h3>
          <i className="bi bi-arrow-left"></i> Voltar
        </h3>
      </Link>
    </div>
  );
};
export default EditPecas;
