import { db } from "../../firebase";
import { useState, useEffect } from "react";

const ModalBob = ({ index, action }) => {
  const [retratos, setRetratos] = useState([]);
  const [indexPic, setIndexPic] = useState(null);
  const [retratoAtual, setRetratoAtual] = useState({
    nome: "",
    ordem: "",
    url: "",
  });

  useEffect(() => {
    const setarRetratos = () => {
      db.collection("retratos")
        .orderBy("timestamp", "desc")
        .onSnapshot(function (snapshot) {
          setRetratos(
            snapshot.docs.map(function (document) {
              return { id: document.id, data: document.data() };
            })
          );
        });
    };
    let retratosVisiveis = retratos.filter(
      (retrato) => retrato.data.visivel === true
    );
    async function setarRetrato() {
      await setarRetratos();
      setRetratoAtual({
        nome: retratosVisiveis[indexPic].data.nome,
        ordem: retratosVisiveis[indexPic].data.ordem,
        url: retratosVisiveis[indexPic].data.url,
      });
    }
    setarRetrato();
  }, [indexPic, index]);

  useEffect(() => {
    setIndexPic(index);
  }, [index]);

  const closeModal = () => {
    let modalBob = document.querySelector(".modal_bob_container");
    modalBob.style.display = "none";
    setRetratoAtual({
      nome: "",
      ordem: "",
      url: "",
    });
    setIndexPic(null);
    action();
  };

  const nextPic = () => {
    let retratosVisiveis = retratos.filter(
      (retrato) => retrato.data.visivel === true
    );
    if (retratosVisiveis.length - 1 === indexPic) {
      setIndexPic(0);
    } else {
      setIndexPic(indexPic + 1);
    }
  };

  const prevPic = () => {
    let retratosVisiveis = retratos.filter(
      (retrato) => retrato.data.visivel === true
    );
    if (indexPic === 0) {
      setIndexPic(retratosVisiveis.length - 1);
    } else {
      setIndexPic(indexPic - 1);
    }
  };

  return (
    <div className="modal_bob_container">
      <div className="modal_bob">
        <i className="bi bi-chevron-left chev" id="prev" onClick={prevPic}></i>
        <i
          className="bi bi bi-chevron-right chev"
          id="next"
          onClick={nextPic}
        ></i>
        <i className="bi bi-x-lg xclose" id="xclose" onClick={closeModal}></i>
        <img alt="" src={retratoAtual.url} />
        <h3>{retratoAtual.nome}</h3>
      </div>
      <div className="clickout" onClick={closeModal}></div>
    </div>
  );
};
export default ModalBob;
