import { db } from "../firebase";
import { useState, useEffect } from "react";
import ContactSection from "./components/ContactSection";
import Paginator from "./components/Paginator";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";

const Palco = () => {
  const [pecas, setPecas] = useState([]);
  const [page, setPage] = useState(1);

  const qtdPg = 9;

  useEffect(() => {
    db.collection("pecas")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (snapshot) {
        setPecas(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);

  const finalPage = Math.ceil(pecas.length / qtdPg);

  //console.log(finalPage)

  let x = page * qtdPg - qtdPg;

  const scllTop = () => {
    scroll.scrollToTop();
  };

  const mapedPecas = pecas.map((el, index) => {
    if (index >= x && index < x + qtdPg) {
      return el;
    } else {
      return null;
    }
  });

  const filteredPecas = mapedPecas.filter((mapedPecas) => mapedPecas !== null);

  //////////AÇÕES////////////

  const setPageValue = (e, par) => {
    e.preventDefault();
    const foo = async () => {
      // do something
      await scllTop();
      if (page !== par) {
        setPage(par);
      }
    };
    foo();
  };

  return (
    <>
      <section id="portfolio">
        <div className="logo_back"></div>
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3>Fotos de Palco</h3>
            <h1>Um breve texto sobre as Fotos de palco.</h1>
          </div>
        </div>
        <div className="palco_wrapper">
          {filteredPecas.map((val) => {
            return (
              <div
                className="container_peca"
                key={val.id}
                style={{ marginBottom: "30px" }}
              >
                <div
                  className="palco_img"
                  style={{ backgroundImage: `url(${val.data.url})` }}
                >
                  <Link to={`/palco/${val.data.slug}`}>
                    <div className="palco_overlay">+</div>
                  </Link>
                </div>
                <h4>{val.data.nome}</h4>
              </div>
            );
          })}
        </div>
        <Paginator atual={page} final={finalPage} acao={setPageValue} />
      </section>

      <ContactSection />
    </>
  );
};
export default Palco;
