import { db } from "../firebase";
import { useState, useEffect } from "react";
import ContactSection from "./components/ContactSection";
import { animateScroll as scroll } from "react-scroll";
import { useParams, useNavigate } from "react-router-dom";
import ModalBobPalco from "./components/ModalBobPalco";
import parse from "html-react-parser";
import Testemonials from "./components/Testemonials";

const AmargemSingle = () => {
  const { id } = useParams();
  const [peca, setPeca] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [modalIndex, setModalIndex] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    db.collection("amargem")
      .where("nome", "==", id)
      .onSnapshot(function (snapshot) {
        setPeca(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    if (peca.length > 0) {
      db.collection("amargem")
        .doc(peca[0].id)
        .collection("images")
        .orderBy("images")
        .onSnapshot(function (snapshot) {
          setImageList(
            snapshot.docs.map(function (document) {
              return { id: document.id, data: document.data() };
            })
          );
        });
    }
  }, [peca]);

  const nomePeca = peca.length > 0 ? peca[0].data.nome : "";
  const imgCapa = peca.length > 0 ? peca[0].data.url : "";
  const txtPeca = peca.length > 0 ? peca[0].data.texto : "";
  const idPeca = peca.length > 0 ? peca[0].id : "";
  const txtPecaHtml = txtPeca ? parse(txtPeca) : "";

  const setIndexNull = () => {
    setModalIndex(null);
  };
  const openModal = (e, par) => {
    e.preventDefault();
    let modalBob = document.querySelector(".modal_bob_container");

    const foo = () => {
      setModalIndex(par);
    };
    const goo = async () => {
      await foo();
      modalBob.style.display = "block";
    };
    goo();
  };

  const obj =
    imageList.length > 0 ? imageList.map((val) => val.data.images) : [];

  const comeBack = () => {
    navigate("/amargempoeticasreveladas");
  };

  return (
    <>
      <ModalBobPalco index={modalIndex} action={setIndexNull} obj={obj} />
      <section id="portfolio">
        <div className="logo_back"></div>
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h3>[Amar(gem)] + Poéticas Reveladas</h3>
            <h1>{nomePeca}</h1>
            <h5 style={{ cursor: "pointer" }} onClick={comeBack}>
              <i className="bi bi-arrow-left"></i> voltar
            </h5>
          </div>
        </div>
        <div
          className="capa_palco_single"
          style={{ backgroundImage: `url(${imgCapa})` }}
        ></div>
        <div className="texto_palco">{txtPecaHtml}</div>
        {peca.map((val) => {
          return <Testemonials key={val.id} uid={val.id} />;
        })}
        <div className="palco_single_wrapper">
          {imageList.map((val, index) => {
            return (
              <div
                key={val.id}
                className="palco_single_img"
                style={{ backgroundImage: `url(${val.data.images})` }}
                onClick={(e) => openModal(e, index)}
              >
                <div className="palco_single_content">
                  <h3>+</h3>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <ContactSection uid={idPeca} />
    </>
  );
};
export default AmargemSingle;
