import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { db, storage } from "../firebase";

const NavProjects = () => {
  const navigate = useNavigate();

  const [projetos, setProjetos] = useState([]);

  useEffect(() => {
    db.collection("projetos")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setProjetos(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  return (
    <ul className="nav-list">
      <h3>Projetos</h3>
      {projetos.map((val) => {
        return (
          <li key={val.id}>
            <Link to={"/projetos/" + val.data.nome}>{val.data.nome}</Link>
          </li>
        );
      })}
    </ul>
  );
};
export default NavProjects;
