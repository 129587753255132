import { Link } from "react-router-dom";

const LoginBob = ({logar})=>{
    return(
        <div className="login_container">
            <h3>Acesso Restrito</h3>
            
            <Link to='/'><button>Voltar para Home</button></Link>

            <form onSubmit={(e)=>logar(e)}>
                <input
                    id="email-login"
                    name="email"
                    placeholder="E-mail..."
                    type="email"
                />
                <input
                    id="senha-login"
                    name="password"
                    placeholder="Senha..."
                    type="password"
                />
                <button type="submit">Login</button>
            </form>
        </div>
    )
}
export default LoginBob