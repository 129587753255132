import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const CadRetratosHome = () => {
  const [file, setFile] = useState(null);
  useEffect(() => {}, []);

  const cadastrarRetrato = (e) => {
    e.preventDefault();
    let nomeRetratado = document.getElementById("nomeRetratado").value;
    var storageRef = firebase.storage().ref();
    let nameRenamed = uuidv4() + file.name;

    document.getElementById("btn_cadastrar").disabled = true;

    if (
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg"
    ) {
      const uploadTask = storageRef
        .child("retratosHome/" + nameRenamed)
        .put(file);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          //document.getElementById('btn_cadastrar').disabled = true;
        },
        function (error) {
          alert(error.message);
        },
        function () {
          storage
            .ref("retratosHome")
            .child(nameRenamed)
            .getDownloadURL()
            .then(function (url) {
              db.collection("retratosHome")
                .add({
                  nome: nomeRetratado,
                  visivel: true,
                  url: url,
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                  alert("Retrato Cadastrado");
                });
              setFile(null);

              document.getElementById("nomeRetratado").value = "";
              document.getElementById("imgSelect").value = "";
              document.getElementById("btn_cadastrar").disabled = false;
            });
        }
      );
    } else {
      alert("Formato de arquivo não aceito pelo aplicativo.");
    }
  };

  return (
    <div className="admin_container">
      <h1>Cadastro de Retratos da Home</h1>
      <form onSubmit={(e) => cadastrarRetrato(e)}>
        <label>Nome do Retratado</label>
        <input type="text" id="nomeRetratado" required />
        <label htmlFor="imgSelect" className="img_select">
          Selecione a Imagem
        </label>
        <input
          type="file"
          id="imgSelect"
          name="file"
          onChange={(e) => setFile(e.target.files[0])}
          required
        />
        <button type="submit" id="btn_cadastrar">
          Cadastrar
        </button>
      </form>

      <Link to="/admin">
        <h3>
          <i className="bi bi-arrow-left"></i> Voltar
        </h3>
      </Link>
    </div>
  );
};
export default CadRetratosHome;
