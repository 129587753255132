import NavProjects from './NavProjects';
import NavRouter from './NavRouter';
import NavScroll from './NavScroll';

const Header =({page})=>{

    const abrirMenu = (e)=>{
        e.preventDefault();

	    var siteBody = document.querySelector('header')
        var menuTrigger = document.getElementById('#header-menu-trigger')
        let closeButton = document.querySelector('.close-button')

        siteBody.classList.toggle("menu-is-open")
        //menuTrigger.classList.toggle("is-clicked")
    }

    const fecharMenu = (e)=>{
        e.preventDefault();

	    var siteBody = document.querySelector('header')
        var menuTrigger = document.getElementById('#header-menu-trigger')
        let closeButton = document.querySelector('.close-button')

        siteBody.classList.remove("menu-is-open")
        //menuTrigger.classList.toggle("is-clicked")
    }

    return(
        <header className='header'>
            <div className="header-logo">
	            <a href="/">BobSousa</a>
	        </div>

            <div id="header-menu-trigger" className="opaque" onClick={abrirMenu}>
                <span className="header-menu-text">Menu</span>
                <span className="header-menu-icon"></span>
            </div>

            <nav  id="menu-nav-wrap" onMouseLeave={fecharMenu}>

            <a className="close-button" title="close" onClick={abrirMenu}><span>Close</span></a>	
                <NavScroll page={page} />
                <ul className="header-social-list">
                    <li>
                        <a href="https://www.facebook.com/bobsousa.hama" target='_blank'><i className="fa fa-facebook-square"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/bobsousa/" target="_blank"><i className="fa fa-instagram"></i></a>
                    </li>
                </ul>
                <NavRouter />
                <NavProjects />
            </nav>
        </header>
    )
}
export default Header