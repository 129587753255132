import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { animateScroll as scroll } from "react-scroll";
import Paginator from "./components/Paginator";
import { Link, useNavigate } from "react-router-dom";
import firebase from "firebase";

const EditRetratos = () => {
  const [pecas, setPecas] = useState([]);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const qtdPg = 18;

  useEffect(() => {
    db.collection("retratos")
      .orderBy("timestamp", "desc")
      .onSnapshot(function (snapshot) {
        setPecas(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);

  const finalPage = Math.ceil(pecas.length / qtdPg);

  //console.log(finalPage)

  let x = page * qtdPg - qtdPg;

  const scllTop = () => {
    scroll.scrollToTop();
  };

  const mapedPecas = pecas.map((el, index) => {
    if (index >= x && index < x + qtdPg) {
      return el;
    } else {
      return null;
    }
  });

  const filteredPecas = mapedPecas.filter((mapedPecas) => mapedPecas !== null);

  //////////AÇÕES////////////

  const setPageValue = (e, par) => {
    e.preventDefault();
    const foo = async () => {
      // do something
      await scllTop();
      if (page !== par) {
        setPage(par);
      }
    };
    foo();
  };

  const deleteVal = (e, par) => {
    e.preventDefault();
    db.collection("retratos").doc(par).delete();
  };

  const editVal = (e, par) => {
    e.preventDefault();
    navigate("/gerenciar-retratos/" + par);
  };

  const setVisible = (e, par, x) => {
    e.preventDefault();
    par === true
      ? db.collection("retratos").doc(x).update({ visivel: false })
      : db.collection("retratos").doc(x).update({ visivel: true });
  };
  const subir = (e, par) => {
    e.preventDefault();
    db.collection("retratosHome").doc(par).update({
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  return (
    <div className="admin_container">
      <h1>Editar Retratos</h1>
      <div className="edit_wraper">
        {filteredPecas.map((val) => {
          return (
            <div className="edit_container" key={val.id}>
              <div
                className="edit_img"
                style={{ backgroundImage: `url(${val.data.url})` }}
              >
                <h4 style={{ color: "white" }}>{val.data.nome}</h4>
              </div>
              <div className="edit_control">
                <span onClick={(e) => deleteVal(e, val.id)}>
                  <i className="bi bi-x-circle-fill"></i> Deletar
                </span>
                {val.data.visivel === false ? (
                  <span
                    onClick={(e) => setVisible(e, val.data.visivel, val.id)}
                    className="visible"
                    style={{ opacity: "0.3" }}
                  >
                    <i className="bi bi-eye-slash"></i>
                  </span>
                ) : (
                  <span
                    onClick={(e) => setVisible(e, val.data.visivel, val.id)}
                    className="visible"
                  >
                    <i className="bi bi-eye"></i>
                  </span>
                )}
                <span onClick={(e) => editVal(e, val.data.nome)}>
                  <i className="bi bi-pencil-fill"></i> Editar
                </span>
                <span onClick={(e) => subir(e, val.id)}>
                  <i className="bi bi-arrow-up-circle"></i> Subir
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Paginator atual={page} final={finalPage} acao={setPageValue} />
      </div>
      <br />
      <Link to="/admin">
        <h3>
          <i className="bi bi-arrow-left"></i> Voltar
        </h3>
      </Link>
    </div>
  );
};
export default EditRetratos;
