import { useEffect, useState } from "react";
import { db } from "../firebase";
import { animateScroll as scroll } from "react-scroll";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModalText from "./components/ModalText";

const EditRetratoHome = () => {
  const { id } = useParams();

  const [retratos, setRetratos] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    db.collection("retratosHome")
      .where("nome", "==", id)
      .onSnapshot(function (snapshot) {
        setRetratos(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
    scroll.scrollToTop();
  }, []);

  const scllTop = () => {
    scroll.scrollToTop();
  };
  window.onload(scllTop());
  //////////AÇÕES////////////

  const atualizarRetrato = (e) => {
    e.preventDefault();
    let nome = document.getElementById("nomeRetrato").value;

    db.collection("retratosHome")
      .doc(retratos[0].id)
      .update({
        nome: nome,
      })
      .then(navigate("/gerenciar-retratos-home"));
  };

  return (
    <>
      <div className="admin_container">
        <h1>Editar o Retrato</h1>
        <div className="edit_wraper">
          {retratos.map((val) => {
            return (
              <div className="edit_container" key={val.id}>
                <ModalText uid={val.id} />
                <div
                  className="edit_img"
                  style={{ backgroundImage: `url(${val.data.url})` }}
                ></div>

                <br />
                <form onSubmit={(e) => atualizarRetrato(e)}>
                  <input
                    type="text"
                    defaultValue={val.data.nome}
                    id="nomeRetrato"
                  />
                  <button type="submit">Atualizar</button>
                </form>
              </div>
            );
          })}
        </div>

        <Link to="/gerenciar-retratos-home">
          <h3>
            <i className="bi bi-arrow-left"></i> Voltar
          </h3>
        </Link>
      </div>
    </>
  );
};
export default EditRetratoHome;
