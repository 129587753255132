import { useState, useEffect } from 'react';

const ModalBobPalco =({index, action, obj})=>{
    const [indexPic, setIndexPic] = useState(null)
    const [imgAtual, setImgAtual] = useState([])


    useEffect(()=>{
        setImgAtual(obj[indexPic])
    },[indexPic, index])

    useEffect(()=>{
        setIndexPic(index)
    },[index])

    const closeModal =()=>{
        let modalBob = document.querySelector('.modal_bob_container');
        modalBob.style.display="none"
            setImgAtual([])
        setIndexPic(null)
        action()
    }

    const nextPic =()=>{
        if(obj.length - 1 === indexPic){
            setIndexPic(0)
        }else{
            setIndexPic(indexPic + 1)
        }
    }

    const prevPic =()=>{
        if(indexPic === 0){
            setIndexPic(obj.length - 1)
        }else{
            setIndexPic(indexPic - 1)
        }
    }

    return(
        <div className="modal_bob_container">
            <div className="modal_bob">
                <i className="bi bi-chevron-left chev" id="prev" onClick={prevPic}></i>
                <i className="bi bi bi-chevron-right chev" id="next" onClick={nextPic}></i>
                <i className="bi bi-x-lg xclose" id="xclose" onClick={closeModal}></i>
                <img alt='' src={imgAtual} />
            </div>
            <div className="clickout" onClick={closeModal}></div>
        </div>
        )
}
export default ModalBobPalco