import { Link, useParams, useNavigate } from "react-router-dom";

const Admin = ({ deslogar }) => {
  return (
    <div className="admin_container">
      <h1>Painel de Controle</h1>
      <div className="painel_controle">
        <ul>
          <Link to="/cadastro-retratos">
            <li>Cadastrar Retrato</li>
          </Link>
          <Link to="/gerenciar-retratos">
            <li>Gerenciar Retratos</li>
          </Link>
          <br />
          <Link to="/cadastro-retratos-home">
            <li>Cadastrar Retrato Home</li>
          </Link>
          <Link to="/gerenciar-retratos-home">
            <li>Gerenciar Retratos Home</li>
          </Link>
          <br />
          <Link to="/cadastro-pecas">
            <li>Cadastrar Peça</li>
          </Link>
          <Link to="/gerenciar-pecas">
            <li>Gerenciar Peças</li>
          </Link>
          <br />
          <Link to="/cadastro-amargem">
            <li>Cadastrar Amargem</li>
          </Link>
          <Link to="/gerenciar-amargem">
            <li>Gerenciar Amargem</li>
          </Link>
          <br />
          <Link to="/cadastro-projetos">
            <li>Cadastro Projetos</li>
          </Link>
          <Link to="/gerenciar-projetos">
            <li>Gerenciar Projetos</li>
          </Link>
          <br />
          <hr style={{ borderTop: "1px solid black" }} />
          <h3>Gerenciamento do Blog</h3>
          <Link to="/cadastro-blog">
            <li>Publicar no Blog</li>
          </Link>
          <Link to="/gerenciar-blog">
            <li>Gerenciar Blog</li>
          </Link>
        </ul>
      </div>
      <button onClick={(e) => deslogar(e)}>Deslogar</button>
    </div>
  );
};
export default Admin;
