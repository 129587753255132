const Paginator =({atual , final, acao})=>{
    return(
        <div className="paginator">
            {atual !== 1
            ? (<div className="paginator_item" onClick={(e)=>acao(e, 1)}><i className="bi bi-chevron-double-left"></i></div>)
            : (<div style={{color:'#bbb'}}><i className="bi bi-chevron-double-left"></i></div>)}
            {atual === final && atual - 4 > 0
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual - 4)}>{atual - 4}</div>)
            : (<></>)}
            {atual >= final - 1 && atual - 3 > 0
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual - 3)}>{atual - 3}</div>)
            : (<></>)}
            {atual > 2
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual - 2)}>{atual - 2}</div>)
            : (<></>)}
            {atual > 1
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual - 1)}>{atual - 1}</div>)
            : (<></>)}
            <div className="paginator_item atual">{atual}</div>
            {atual < final
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual + 1)}>{atual + 1}</div>)
            : (<></>)}
            {atual +2 <= final
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual + 2)}>{atual + 2}</div>)
            : (<></>)}
            {atual + 3 <= final && atual < 3 
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual + 3)}>{atual + 3}</div>)
            : (<></>)}
            {atual + 4 <= final && atual < 2
            ? (<div className="paginator_item" onClick={(e)=>acao(e, atual + 4)}>{atual + 4}</div>)
            : (<></>)}
            {atual !== final
            ? (<div className="paginator_item" onClick={(e)=>acao(e, final)}><i className="bi bi-chevron-double-right"></i></div>)
            :(<div style={{color:'#bbb'}}><i className="bi bi-chevron-double-right"></i></div>)}
        </div>
    )
}
export default Paginator